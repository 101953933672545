<template>
  <PromptDialog
    :title="title"
    :isOpened="isOpened"
    :context="context"
    :width="width"
    :formSchema="formSchema"
    :formFieldValues="getFormData"
    :showRequiredFieldsAdvice="showRequiredFieldsAdvice"
    :parentField="props.parentField"
    @execute-action="onExecuteAction"
    @changeFormField="onChangeFormFieldValue"
  ></PromptDialog>
</template>
<script lang="ts" setup>
import { ActionName } from '@/components/ActionsBar/types/ActionBarComponentTypes'
import PromptDialog from '@/components/Dialog/PromptDialogComponent.vue'
import useFormSchema from '@/composables/useFormSchema'
import { MainService } from '@/services/MainService'
import Vue, { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n-composable'
import { ContactFields } from '@/views/contacts/Contacts/types/ContactFieldTypes'
import { ContactPersonTypeId } from '@/store/modules/contacts/contactsTypes'
import useParentFields from '@/composables/useParentFields'

// DEFINE EMITS  ======================================================================================================
const emits = defineEmits(['close', 'save'])

// DEFINE PROPS  ======================================================================================================
const props = defineProps({
  title: {
    type: String,
    default: ''
  },
  isOpened: {
    type: Boolean,
    default: false,
    required: true
  },
  width: {
    type: Number,
    default: 940
  },
  field: {
    type: Object,
    default: () => ({})
  },
  formAlias: {
    type: String,
    default: null
  },
  saveEndPoint: {
    type: String,
    required: true
  },
  relatedData: {
    type: String,
    default: null
  },
  filterField: {
    type: String,
    default: null
  },
  filteredValue: {
    type: String,
    default: null,
  },
  showRequiredFieldsAdvice: {
    type: Boolean,
    default: true,
    required: false
  },
  parentField: {
    type: String,
    default: null,
    required: false
  }
})

const { t } = useI18n()
const formData = ref({})

const texts = {
  nameNatural: t('components.contact.name_natural'),
  nameLegal: t('components.contact.name_legal')
}

// COMPOSABLES   ======================================================================================================
const { formSchema: formSchemaDB, getFormSchema } = useFormSchema({
  formAlias: props.formAlias,
  context: props.formAlias
})

const { getParentFieldValues, setParentFieldValues } = useParentFields()
// STORE ACTIONS ======================================================================================================

// STORE GETTERS ======================================================================================================

// COMPUTEDS ==========================================================================================================

const formSchema = computed(() => {
  if (props.formAlias) {
    return formSchemaDB.value
  }
  return [
    {
      fieldType: 'LexonTextFieldComponent',
      id: `selectForm-${props.field.id}`,
      label: props.field.label,
      name: 'name',
      type: 'text',
      validationRules: 'required|max:60',
      xl: 12,
      md: 12,
      lg: 12
    }
  ]
})

const context = computed(() => {
  return props.field.id
})

const getFormData = computed(() => {
  setValueByParentFieldValues()
  return formData
})

// EVENTS =============================================================================================================
const onExecuteAction = (actionName: string) => {
  switch (actionName) {
    case ActionName.SAVE:
      saveItems()
      break
    case ActionName.CLOSE:
      formData.value = {}
      break
  }
  emits('close')
}

const onChangeFormFieldValue = async (schema: [], formData: any, field: any, value: any) => {
  if (ContactFields.PERSON_TYPE === field.id) {
    const nameItem: any = schema.find((item: any) => item.id === ContactFields.NAME)
    if (nameItem) {
      nameItem.label = value === ContactPersonTypeId.NATURAL ? texts.nameNatural : texts.nameLegal
    }

    const legalTypeItem: any = schema.find((item: any) => item.id === ContactFields.LEGAL_TYPE)
    if (legalTypeItem) {
      legalTypeItem.hidden = value === ContactPersonTypeId.NATURAL
    }

    const otherItems: any = schema.filter(
      (item: any) =>
        item.id === ContactFields.FIRST_SURNAME ||
        item.id === ContactFields.SECOND_SURNAME ||
        item.id === ContactFields.BIRTHDAY
    )
    otherItems.forEach((item: any) => {
      item.hidden = value !== ContactPersonTypeId.NATURAL
    })
  }
}

// METHODS ============================================================================================================

const saveItems = async () => {
  setFormData()
  const { id } = await save(formData.value)
  const newItem = {
    id,
    ...formData.value
  }
  //setInParentValues()
  formData.value = {}
  emits('save', newItem)
}

const save = async (json: any) => {
  const saveEndPoint = getEndpoint()
  try {
    const { data } = await new MainService().postData(saveEndPoint, json)
    return data
  } catch (error) {
    return null
  }
}

function getEndpoint() {
  if (props.relatedData) {
    return props.saveEndPoint.replace(/\{(.+?)\}/g, props.relatedData)
  }
  return props.saveEndPoint
}

const setValueByParentFieldValues = () => {
  if (props.parentField) {
    const parentFieldValues: any = getParentFieldValues()
    const value = parentFieldValues && parentFieldValues[props.parentField] !== null && parentFieldValues[props.parentField] !== undefined
      ? parentFieldValues[props.parentField]
      : null
    if(parentFieldValues) {
      Vue.set(formData.value, props.parentField, value)
    }
  }
}

const setFormData = () => {
  if (props.filterField && props.filteredValue && !props.parentField) {
    Vue.set(formData.value, props.filterField, props.filteredValue)
  }

  if (props.parentField && formData.value.hasOwnProperty(props.parentField)) {
    const parentFieldValue = (formData as any).value[props.parentField]
    if (!parentFieldValue || parentFieldValue === null) {
        delete (formData as any).value[props.parentField]
    }
  }
}

// HOOKS =============================================================================================================
if (props.formAlias) {
  getFormSchema()
}
</script>
