import ConfigurationPanelView from '@/views/ConfigurationPanel/ConfigurationPanelView.vue'
import { URLS, configuracionPanelURLS } from '@/router/routes/urlRoutes'
import { ConfigurationAlias, ConfigurationTypes } from '@/store/modules/configuration/configurationTypes'
import { entity } from '@/store/modules/entities/entitiesTypes'
import { CONFIGURATION_PANEL_MENU_NAMES } from '@/views/ConfigurationPanel/types/configurationPanelTypes'
import { normalizeText } from '@/helpers/helpers'


export const configurationPanelRoutes = [
  {
    path: normalizeText(`/${URLS.CONFIGURATION_PANEL}`),
    name: URLS.CONFIGURATION_PANEL,
    component: ConfigurationPanelView,
    meta: {
      requiresAuth: true,
      title: URLS.CONFIGURATION_PANEL,
      isConfigurationPanel: true
    }
  },
  {
    path: normalizeText(`/panel-configuracion/usuarios`),
    name: `${URLS.CONFIGURATION_PANEL}-${URLS.CONFIGURATION_PANEL_USUARIOS}`,
    component: () => import('@/views/ConfigurationPanel/User/UserView.vue'),
    props: () => ({
      menuName: CONFIGURATION_PANEL_MENU_NAMES.USER_MANAGEMENT,
      context: `${URLS.CONFIGURATION_PANEL}-${URLS.CONFIGURATION_PANEL_USUARIOS}`,
      showBreadcrumbs: false,
      configuration: {
        type: ConfigurationTypes.VIEW,
        alias: ConfigurationAlias.MAINTENANCE_USERS
      },
      entityType: entity.maintenance.type,
      entityId: 0
    }),
    meta: {
      requiresAuth: true,
      parentUrl: URLS.CONFIGURATION_PANEL,
      title: URLS.CONFIGURATION_PANEL_USUARIOS,
      isConfigurationPanel: true
    }
  },
  {
    path: normalizeText(`/panel-configuracion/portal/general`),
    name: `${URLS.CONFIGURATION_PANEL}-${URLS.CONFIGURATION_PANEL_PORTAL_GENERAL}`,
    component: () => import('@/views/ConfigurationPanel/CustomerPortal/CustomerPortalView.vue'),
    props: () => ({
      menuName: CONFIGURATION_PANEL_MENU_NAMES.CUSTOMER_PORTAL,
      context: `${URLS.CONFIGURATION_PANEL}-${URLS.CONFIGURATION_PANEL_PORTAL_GENERAL}`,
      showBreadcrumbs: false,
      configuration: {
        type: ConfigurationTypes.VIEW,
        alias: 'configurationPanelCustomerPortalGeneral'
      },
      entityType: entity.configurationPanel.type,
      entityId: 'manCustomerDatosPortal'
    }),
    meta: {
      requiresAuth: true,
      parentUrl: URLS.CONFIGURATION_PANEL,
      title: URLS.CONFIGURATION_PANEL_PORTAL_GENERAL,
      isConfigurationPanel: true
    }
  },
  {
    path: normalizeText(`/panel-configuracion/portal/plantillas-emails`),
    name: `${URLS.CONFIGURATION_PANEL}-${URLS.CONFIGURATION_PANEL_PORTAL_EMAIL_TEMPLATES}`,
    component: () => import('@/views/ConfigurationPanel/CustomerPortal/CustomerPortalEmailTemplateView.vue'),
    props: () => ({
      menuName: CONFIGURATION_PANEL_MENU_NAMES.EMAIL_TEMPLATES,
      context: `${URLS.CONFIGURATION_PANEL}-${URLS.CONFIGURATION_PANEL_PORTAL_EMAIL_TEMPLATES}`,
      showBreadcrumbs: false,
      configuration: {
        type: ConfigurationTypes.VIEW,
        alias: 'configurationPanelCustomerPortalPlantillasEmail'
      },
      entityType: entity.configurationPanel.type,
      entityId: 'manCustomerPortal'
    }),
    meta: {
      requiresAuth: true,
      parentUrl: URLS.CONFIGURATION_PANEL,
      title: URLS.CONFIGURATION_PANEL_PORTAL_EMAIL_TEMPLATES,
      isConfigurationPanel: true
    }
  },
  {
    path: normalizeText(`/panel-configuracion/actuaciones/categoria`),
    name: `${URLS.CONFIGURATION_PANEL}-${URLS.CONFIGURATION_PANEL_ACTIONS_CATEGORY}`,
    component: () => import('@/views/ConfigurationPanel/ActionsCategory/ActionsCategoryView.vue'),
    meta: {
      requiresAuth: true,
      parentUrl: URLS.CONFIGURATION_PANEL,
      title: URLS.CONFIGURATION_PANEL_ACTIONS_CATEGORY,
      isConfigurationPanel: true
    }
  },
  {
    path: normalizeText(`/panel-configuracion/actuaciones/codigo-color`),
    name: `${URLS.CONFIGURATION_PANEL}-${URLS.CONFIGURATION_PANEL_ACTIONS_COLOR_CODE}`,
    component: () => import('@/views/ConfigurationPanel/ActionsColorCode/ActionsColorCodeView.vue'),
    meta: {
      requiresAuth: true,
      parentUrl: URLS.CONFIGURATION_PANEL,
      title: URLS.CONFIGURATION_PANEL_ACTIONS_COLOR_CODE,
      isConfigurationPanel: true
    }
  },
  {
    path: normalizeText(`/panel-configuracion/actuaciones/codificacion`),
    name: `${URLS.CONFIGURATION_PANEL}-${URLS.CONFIGURATION_PANEL_ACTIONS_CODIFICATION}`,
    component: () => import('@/views/ConfigurationPanel/ActionsCodification/ActionsCodificationView.vue'),
    meta: {
      requiresAuth: true,
      parentUrl: URLS.CONFIGURATION_PANEL,
      title: URLS.CONFIGURATION_PANEL_ACTIONS_CODIFICATION,
      isConfigurationPanel: true
    }
  },
  {
    path: normalizeText(`/panel-configuracion/actuaciones/codificacion/:selectedRegisterId`),
    name: `${URLS.CONFIGURATION_PANEL}-${URLS.CONFIGURATION_PANEL_ACTIONS_CODIFICATION_DETAIL}`,
    component: () => import('@/views/ConfigurationPanel/ConfigurationPanelDetailsView.vue'),
    props: (route: { params: { selectedRegisterId: any } }) => ({
      menuName: CONFIGURATION_PANEL_MENU_NAMES.CODIFICATION,
      context: `${URLS.CONFIGURATION_PANEL}-${URLS.CONFIGURATION_PANEL_ACTIONS_CODIFICATION_DETAIL}`,
      configuration: {
        type: ConfigurationTypes.VIEW,
        alias: 'maintenanceActionsCodification'
      },
      entityType: entity.maintenance.type,
      entityId: !isNaN(parseInt(route.params.selectedRegisterId, 10))
        ? parseInt(route.params.selectedRegisterId, 10)
        : 0,
      source: 'manActionsCodifications'
    }),
    meta: {
      requiresAuth: true,
      parentUrl: URLS.CONFIGURATION_PANEL,
      title: URLS.CONFIGURATION_PANEL_ACTIONS_CODIFICATION_DETAIL,
      isConfigurationPanel: true
    }
  },
  {
    path: normalizeText(`/panel-configuracion/facturacion/formas-de-pago`),
    name: `${URLS.CONFIGURATION_PANEL}-${URLS.CONFIGURATION_PANEL_BILLING_PAYMENT_TYPES}`,
    component: () => import('@/views/ConfigurationPanel/BillingPaymentTypes/BillingPaymentTypesView.vue'),
    meta: {
      requiresAuth: true,
      parentUrl: URLS.CONFIGURATION_PANEL,
      title: URLS.CONFIGURATION_PANEL_BILLING_PAYMENT_TYPES,
      isConfigurationPanel: true
    }
  },
  {
    path: normalizeText(`/panel-configuracion/facturacion/agrupacion-de-impuestos`),
    name: `${URLS.CONFIGURATION_PANEL}-${URLS.CONFIGURATION_PANEL_BILLING_TAXES_GROUPING}`,
    component: () => import('@/views/ConfigurationPanel/BillingTaxesGrouping/BillingTaxesGroupingView.vue'),
    meta: {
      requiresAuth: true,
      parentUrl: URLS.CONFIGURATION_PANEL,
      title: URLS.CONFIGURATION_PANEL_BILLING_TAXES_GROUPING,
      isConfigurationPanel: true
    }
  },
  {
    path: normalizeText(`/panel-configuracion/facturacion/impuestos`),
    name: `${URLS.CONFIGURATION_PANEL}-${URLS.CONFIGURATION_PANEL_BILLING_TAXES}`,
    component: () => import('@/views/ConfigurationPanel/BillingTaxes/BillingTaxesView.vue'),
    meta: {
      requiresAuth: true,
      parentUrl: URLS.CONFIGURATION_PANEL,
      title: URLS.CONFIGURATION_PANEL_BILLING_TAXES,
      isConfigurationPanel: true
    }
  },
  {
    path: normalizeText(`/panel-configuracion/facturacion/conceptos-de-facturas-clientes`),
    name: `${URLS.CONFIGURATION_PANEL}-${URLS.CONFIGURATION_PANEL_BILLING_CUSTOMER_INVOICES_CONCEPTS}`,
    component: () => import('@/views/ConfigurationPanel/BillingInvoicesConcepts/BillingCustomerInvoicesConceptsView.vue'),
    meta: {
      requiresAuth: true,
      parentUrl: URLS.CONFIGURATION_PANEL,
      title: URLS.CONFIGURATION_PANEL_BILLING_CUSTOMER_INVOICES_CONCEPTS,
      isConfigurationPanel: true
    }
  },
  {
    path: normalizeText(`/panel-configuracion/facturacion/conceptos-de-facturas-proveedores`),
    name: `${URLS.CONFIGURATION_PANEL}-${URLS.CONFIGURATION_PANEL_BILLING_PROVIDER_INVOICES_CONCEPTS}`,
    component: () => import('@/views/ConfigurationPanel/BillingInvoicesConcepts/BillingProviderInvoicesConceptsView.vue'),
    meta: {
      requiresAuth: true,
      parentUrl: URLS.CONFIGURATION_PANEL,
      title: URLS.CONFIGURATION_PANEL_BILLING_PROVIDER_INVOICES_CONCEPTS,
      isConfigurationPanel: true
    }
  },
  {
    path: normalizeText(`/panel-configuracion/facturacion/tarifas`),
    name: `${URLS.CONFIGURATION_PANEL}-${URLS.CONFIGURATION_PANEL_BILLING_TARIFFS}`,
    component: () => import('@/views/ConfigurationPanel/BillingTariffs/BillingTariffsView.vue'),
    meta: {
      requiresAuth: true,
      parentUrl: URLS.CONFIGURATION_PANEL,
      title: URLS.CONFIGURATION_PANEL_BILLING_TARIFFS,
      isConfigurationPanel: true
    }
  },
  {
    path: normalizeText(`/panel-configuracion/facturacion/configuracion-de-costes`),
    name: `${URLS.CONFIGURATION_PANEL}-${URLS.CONFIGURATION_PANEL_BILLING_COST}`,
    component: () => import('@/views/ConfigurationPanel/BillingCost/BillingCostView.vue'),
    meta: {
      requiresAuth: true,
      parentUrl: URLS.CONFIGURATION_PANEL,
      title: URLS.CONFIGURATION_PANEL_BILLING_COST,
      isConfigurationPanel: true
    }
  },
  {
    path: normalizeText(`/panel-configuracion/facturacion/profesionales`),
    name: `${URLS.CONFIGURATION_PANEL}-${URLS.CONFIGURATION_PANEL_BILLING_PROFESSIONALS}`,
    component: () => import('@/views/ConfigurationPanel/BillingProfessionals/BillingProfessionalsView.vue'),
    meta: {
      requiresAuth: true,
      parentUrl: URLS.CONFIGURATION_PANEL,
      title: URLS.CONFIGURATION_PANEL_BILLING_PROFESSIONALS,
      isConfigurationPanel: true
    }
  },
  {
    path: normalizeText(`/panel-configuracion/contactos/categoria`),
    name: `${URLS.CONFIGURATION_PANEL}-${URLS.CONFIGURATION_PANEL_CONTACTS_CATEGORY}`,
    component: () => import('@/views/ConfigurationPanel/ContactsCategory/ContactsCategoryView.vue'),
    meta: {
      requiresAuth: true,
      parentUrl: URLS.CONFIGURATION_PANEL,
      title: URLS.CONFIGURATION_PANEL_CONTACTS_CATEGORY,
      isConfigurationPanel: true
    }
  },
  {
    path: normalizeText(`/panel-configuracion/expedientes/areas-juridicas`),
    name: `${URLS.CONFIGURATION_PANEL}-${URLS.CONFIGURATION_PANEL_EXPEDIENTS_LEGAL_AREAS}`,
    component: () => import('@/views/ConfigurationPanel/ExpedientsCategory/ExpedientsCategoryView.vue'),
    meta: {
      requiresAuth: true,
      parentUrl: URLS.CONFIGURATION_PANEL,
      title: URLS.CONFIGURATION_PANEL_EXPEDIENTS_LEGAL_AREAS,
      isConfigurationPanel: true
    }
  },
  {
    path: normalizeText(`/panel-configuracion/expedientes/sujetos-procesales`),
    name: `${URLS.CONFIGURATION_PANEL}-${URLS.CONFIGURATION_PANEL_EXPEDIENTS_PARTIES}`,
    component: () => import('@/views/ConfigurationPanel/ExpedientsParties/ExpedientsPartiesView.vue'),
    meta: {
      requiresAuth: true,
      parentUrl: URLS.CONFIGURATION_PANEL,
      title: URLS.CONFIGURATION_PANEL_EXPEDIENTS_PARTIES,
      isConfigurationPanel: true
    }
  },
  {
    path: normalizeText(`/panel-configuracion/expedientes/situaciones`),
    name: `${URLS.CONFIGURATION_PANEL}-${URLS.CONFIGURATION_PANEL_EXPEDIENTS_SITUATIONS}`,
    component: () => import('@/views/ConfigurationPanel/ExpedientsSituations/ExpedientsSituationsView.vue'),
    meta: {
      requiresAuth: true,
      parentUrl: URLS.CONFIGURATION_PANEL,
      title: URLS.CONFIGURATION_PANEL_EXPEDIENTS_SITUATIONS,
      isConfigurationPanel: true
    }
  },
  {
    path: normalizeText(`/panel-configuracion/expedientes/etapas-procesales`),
    name: `${URLS.CONFIGURATION_PANEL}-${URLS.CONFIGURATION_PANEL_EXPEDIENTS_STAGES}`,
    component: () => import('@/views/ConfigurationPanel/ExpedientsStages/ExpedientsStagesView.vue'),
    meta: {
      requiresAuth: true,
      parentUrl: URLS.CONFIGURATION_PANEL,
      title: URLS.CONFIGURATION_PANEL_EXPEDIENTS_STAGES,
      isConfigurationPanel: true
    }
  },
  {
    path: normalizeText(`/panel-configuracion/expedientes/areas-negocio`),
    name: `${URLS.CONFIGURATION_PANEL}-${URLS.CONFIGURATION_PANEL_EXPEDIENTS_BUSSINESS_AREAS}`,
    component: () => import('@/views/ConfigurationPanel/ExpedientsBussiness/ExpedientsBussinessView.vue'),
    meta: {
      requiresAuth: true,
      parentUrl: URLS.CONFIGURATION_PANEL,
      title: URLS.CONFIGURATION_PANEL_EXPEDIENTS_BUSSINESS_AREAS,
      isConfigurationPanel: true
    }
  },
  {
    path: normalizeText(`/panel-configuracion/expedientes/modulos`),
    name: `${URLS.CONFIGURATION_PANEL}-${URLS.CONFIGURATION_PANEL_EXPEDIENTS_MODULE}`,
    component: () => import('@/views/ConfigurationPanel/ExpedientsModules/ExpedientsModulesView.vue'),
    meta: {
      requiresAuth: true,
      parentUrl: URLS.CONFIGURATION_PANEL,
      title: URLS.CONFIGURATION_PANEL_EXPEDIENTS_MODULE,
      isConfigurationPanel: true
    }
  },
  {
    path: normalizeText(`/panel-configuracion/expedientes/conceptos`),
    name: `${URLS.CONFIGURATION_PANEL}-${URLS.CONFIGURATION_PANEL_EXPEDIENTS_CONCEPT}`,
    component: () => import('@/views/ConfigurationPanel/ExpedientsConcepts/ExpedientsConceptsView.vue'),
    meta: {
      requiresAuth: true,
      parentUrl: URLS.CONFIGURATION_PANEL,
      title: URLS.CONFIGURATION_PANEL_EXPEDIENTS_CONCEPT,
      isConfigurationPanel: true
    }
  },
  {
    path: normalizeText(`/panel-configuracion/expedientes/asuntos`),
    name: `${URLS.CONFIGURATION_PANEL}-${URLS.CONFIGURATION_PANEL_EXPEDIENTS_SUBJECTS}`,
    component: () => import('@/views/ConfigurationPanel/ExpedientsSubjects/ExpedientsSubjectsView.vue'),
    meta: {
      requiresAuth: true,
      parentUrl: URLS.CONFIGURATION_PANEL,
      title: URLS.CONFIGURATION_PANEL_EXPEDIENTS_SUBJECTS,
      isConfigurationPanel: true
    }
  },
  {
    path: normalizeText(`/panel-configuracion/expedientes/clasificacion`),
    name: `${URLS.CONFIGURATION_PANEL}-${URLS.CONFIGURATION_PANEL_EXPEDIENTS_TAGS}`,
    component: () => import('@/views/ConfigurationPanel/ExpedientsTags/ExpedientsTagsView.vue'),
    meta: {
      requiresAuth: true,
      parentUrl: URLS.CONFIGURATION_PANEL,
      title: URLS.CONFIGURATION_PANEL_EXPEDIENTS_TAGS,
      isConfigurationPanel: true
    }
  },
  {
    path: normalizeText(`/panel-configuracion/expedientes/tipos-de-expedientes`),
    name: `${URLS.CONFIGURATION_PANEL}-${URLS.CONFIGURATION_PANEL_EXPEDIENTS_TYPES}`,
    component: () => import('@/views/ConfigurationPanel/ExpedientsTypes/ExpedientsTypesView.vue'),
    meta: {
      requiresAuth: true,
      parentUrl: URLS.CONFIGURATION_PANEL,
      title: URLS.CONFIGURATION_PANEL_EXPEDIENTS_TYPES,
      isConfigurationPanel: true
    }
  },
  {
    path: normalizeText(`/panel-configuracion/expedientes/campos-personalizados`),
    name: `${URLS.CONFIGURATION_PANEL}-${URLS.CONFIGURATION_PANEL_EXPEDIENTS_CUSTOM_FIELDS}`,
    component: ConfigurationPanelView,
    props: () => ({
      menuName: CONFIGURATION_PANEL_MENU_NAMES.CUSTOM_FIELDS,
      context: `${URLS.CONFIGURATION_PANEL}-${URLS.CONFIGURATION_PANEL_EXPEDIENTS_CUSTOM_FIELDS}`,
      showBreadcrumbs: false,
      showTitleHeader: false,
      configuration: {
        type: ConfigurationTypes.VIEW,
        alias: 'configurationPanelCustomFields'
      },
      entityType: entity.configurationPanel.type,
      entityId: 0
    }),
    meta: {
      requiresAuth: true,
      parentUrl: URLS.CONFIGURATION_PANEL,
      title: URLS.CONFIGURATION_PANEL_EXPEDIENTS_CUSTOM_FIELDS,
      isConfigurationPanel: true
    },
  },
  {
    path: normalizeText(`/panel-configuracion/expedientes/numeracion-automatica`),
    name: `${URLS.CONFIGURATION_PANEL}-${URLS.CONFIGURATION_PANEL_EXPEDIENTS_AUTOMATIC_NUMBERING}`,
    component: () => import('@/views/ConfigurationPanel/ExpedientNumeration/ExpedientNumerationView.vue'),
    props: () => ({
      menuName: CONFIGURATION_PANEL_MENU_NAMES.AUTOMATIC_NUMBERING,
      context: `${URLS.CONFIGURATION_PANEL}-${URLS.CONFIGURATION_PANEL_EXPEDIENTS_AUTOMATIC_NUMBERING}`,
      showBreadcrumbs: false,
      configuration: {
        type: ConfigurationTypes.VIEW,
        alias: 'configurationPanelAutomaticNumbering'
      },
      entityType: entity.configurationPanel.type,
      entityId: 0
    }),
    meta: {
      requiresAuth: true,
      parentUrl: URLS.CONFIGURATION_PANEL,
      title: URLS.CONFIGURATION_PANEL_EXPEDIENTS_AUTOMATIC_NUMBERING,
      isConfigurationPanel: true
    }
  },
  {
    path: normalizeText(`/panel-configuracion/documentos/categoria`),
    name: `${URLS.CONFIGURATION_PANEL}-${URLS.CONFIGURATION_PANEL_DOCUMENTS_CATEGORY}`,
    component: () => import('@/views/ConfigurationPanel/DocumentsCategory/DocumentsCategoryView.vue'),
    meta: {
      requiresAuth: true,
      parentUrl: URLS.CONFIGURATION_PANEL,
      title: URLS.CONFIGURATION_PANEL_DOCUMENTS_CATEGORY,
      isConfigurationPanel: true
    }
  },
  {
    path: normalizeText(configuracionPanelURLS.DOCUMENTS_TEMPLATES),
    name: `${URLS.CONFIGURATION_PANEL}-${URLS.CONFIGURATION_PANEL_DOCUMENTS_TEMPLATES}`,
    component: ConfigurationPanelView,
    props: () => ({
      menuName: CONFIGURATION_PANEL_MENU_NAMES.DOCUMENT_TEMPLATES,
      context: `${URLS.CONFIGURATION_PANEL}-${URLS.CONFIGURATION_PANEL_DOCUMENTS_TEMPLATES}`,
      showBreadcrumbs: false,
      configuration: {
        type: ConfigurationTypes.VIEW,
        alias: 'configurationPanelDocumentTemplates'
      },
      entityType: entity.configurationPanel.type,
      entityId: 0
    }),
    meta: {
      requiresAuth: true,
      parentUrl: URLS.CONFIGURATION_PANEL,
      title: URLS.CONFIGURATION_PANEL_DOCUMENTS_TEMPLATES,
      isConfigurationPanel: true
    }
  },
  {
    path: normalizeText(`/panel-configuracion/alertas`),
    name: `${URLS.CONFIGURATION_PANEL}-${URLS.MAINTENANCE_ALERTS}`,
    component: () => import('@/views/ConfigurationPanel/AlertsConfiguration/AlertsConfigurationView.vue'),
    meta: {
      requiresAuth: true,
      parentUrl: URLS.CONFIGURATION_PANEL,
      title: URLS.MAINTENANCE_ALERTS,
      isConfigurationPanel: true
    }
  }
]
