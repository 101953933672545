import { computed, ComputedRef, ref } from 'vue'
import { defineStore } from 'pinia'
import { GridConfiguration } from '@/components/grids/LfGrid/LfGridTypes'
import useUser from '@/composables/useUser'
import { jsonAreDifferent } from '@/helpers/json'
import { MainService } from '@/services/MainService'

export const useGridStore = defineStore('grid', () => {
  const { userId } = useUser()

  const listConfiguration = ref<{ [key: string]: GridConfiguration }>({})

  async function fetchListConfiguration(alias: string) {
    if (listConfiguration.value[alias]) {
      return
    }
    const url = `configuration/user/lists/${userId.value}/${alias}/get`
    const { data } = await new MainService().getData(url)
    const config = JSON.parse(data[0].Config)
    setListConfiguration(alias, config)
  }

  function saveListConfiguration(alias: string, config: GridConfiguration) {
    const data = {
      IdUser: userId.value,
      Alias: alias,
      Config: config
    }

    if (!areDifferents(config, alias)) {
      return
    }

    setListConfiguration(alias, config)
    const url = 'configuration/user/lists/save'
    new MainService().postData(url, data)
  }

  function removeCommandColumn(config: GridConfiguration): GridConfiguration {
    return {...config, columns: config.columns.filter(item => !item.commands)}
  }

  function areDifferents(config: GridConfiguration, alias: string) {
    const configStore = removeCommandColumn(listConfiguration.value[alias])
    const configBD = removeCommandColumn(config)
    return jsonAreDifferent(configStore, configBD)
  }

  function setListConfiguration(context: string, data: GridConfiguration) {
    listConfiguration.value[context] = data
  }

  function getListConfiguration(context: string): ComputedRef<GridConfiguration> {
    return computed(() => listConfiguration.value[context])
  }

  return {
    listConfiguration,
    saveListConfiguration,
    fetchListConfiguration,
    setListConfiguration,
    getListConfiguration
  }
})
