import { ActionTree } from 'vuex'
import {
  AuthData,
  authLexonToken,
  authMutationTypes,
  AuthState,
  Credentials,
  LogoutArgs
} from '@/store/modules/auth/authTypes'
import { ModuleNamespaces, RootState } from '@/store/types/storeGlobalTypes'
import { createToken } from '@/helpers/helpers'
import { AuthService } from '@/services/AuthService'
import { http } from '@/plugins/axios'
import { spinnerMutationTypes } from '@/store/modules/spinner/spinnerTypes'
import store from '@/store/store'
import { errorsMutationTypes } from '@/store/modules/errors/errorsTypes'
import { MainService } from '@/services/MainService'
import { router } from '@/router/router'
import { URLS } from '@/router/routes/urlRoutes'

export const actions: ActionTree<AuthState, RootState> = {
  async login({ commit, state }, credentials: Credentials) {
    try {
      store.commit(`spinnerModule/${spinnerMutationTypes.SHOW_SPINNER}`)
      const url = 'login'
      const token = createToken(state.salt)
      http.defaults.headers.common[authLexonToken] = token

      const response = await new AuthService().login(url, credentials)
      const { data } = response
      if (data && data.redirectUrl) {
        setTimeout(() => {
          store.commit(`spinnerModule/${spinnerMutationTypes.SHOW_SPINNER}`)
        })
        window.location.href = data.redirectUrl
      }
      commit(authMutationTypes.SET_PRE_AUTH_DATA, data)
      delete http.defaults.headers.common[authLexonToken]
    } catch (error) {
      const errorStatus = error && error.response && error.response.status ? error.response.status : 0
      switch (errorStatus) {
        case 404:
        case 503:
          commit(`${ModuleNamespaces.ERROR}/${errorsMutationTypes.SET_ERROR}`, 'errors.server_error', { root: true })
          break
        case 401:
          commit(`${ModuleNamespaces.ERROR}/${errorsMutationTypes.SET_ERROR}`, 'errors.user_or_password_wrong', {
            root: true
          })
          break
        default:
          commit(`${ModuleNamespaces.ERROR}/${errorsMutationTypes.SET_ERROR}`, 'errors.server_error', { root: true })
          break
      }
      store.commit(`spinnerModule/${spinnerMutationTypes.HIDE_SPINNER}`)
    }
  },
  async navisionLogin({ commit, state }, navisionToken) {
    try {
      const url = 'navision-login'
      const token = createToken(state.salt)
      http.defaults.headers.common[authLexonToken] = token

      const response = await new AuthService().navisionLogin(navisionToken, url)
      const { data } = response
      commit(authMutationTypes.SET_PRE_AUTH_DATA, data)
      delete http.defaults.headers.common[authLexonToken]
    } catch (error) {}
  },
  async selectCompany({ commit }, idCompany: string) {
    try {
      store.commit(`spinnerModule/${spinnerMutationTypes.SHOW_SPINNER}`)
      const url = `login/company/${idCompany}/select`
      const response = await new AuthService().selectCompany(url)
      const { data } = response
      commit(authMutationTypes.SET_AUTH_DATA, data)
      commit(authMutationTypes.SET_LAST_REFRESH_TOKEN_TIME, new Date().getTime())
      commit(authMutationTypes.RESET_PRE_AUTH_DATA)
    } catch (error) {
      commit(`${ModuleNamespaces.ERROR}/${errorsMutationTypes.SET_ERROR}`, 'errors.server_error', { root: true })
      router.push(`/${URLS.LOGIN}`)
    }
  },
  async refreshToken({ commit, getters }) {
    try {
      const url = 'auth/token/get'
      const idCompany = getters.getCompany.idCompany
      const userName = getters.getUserEmail
      const refreshToken = getters.getRefreshToken
      const paramsObject = { idCompany, userName }
      delete http.defaults.headers.common['Authorization']
      http.defaults.headers.common['Authorization'] = `Bearer ${refreshToken}`
      const response = await new AuthService().refreshToken(url, paramsObject)
      const { data } = response
      commit(authMutationTypes.SET_AUTH_DATA, data)
      commit(authMutationTypes.SET_LAST_REFRESH_TOKEN_TIME, new Date().getTime())
    } catch (error) {}
  },
  async setAuthDataAction({commit}, authData: AuthData): Promise<void> {
    try {
      commit(authMutationTypes.SET_AUTH_DATA, authData)
      commit(authMutationTypes.SET_LAST_REFRESH_TOKEN_TIME, new Date().getTime())
    } catch(error) {
      commit(authMutationTypes.LOGOUT)
    }
  },
  async logout({ commit }) {
    try {
      commit(authMutationTypes.LOGOUT)
    } catch (error) {}
  },
  async navisionLogout({ commit }) {
    try {
      const args: LogoutArgs = {
        isNavision: true
      }
      commit(authMutationTypes.LOGOUT, args)
    } catch (error) {}
  },
  cancelPendingRequests({ state, commit }) {
    state.cancelTokens.forEach((request: any) => {
      if (request.cancel) {
        request.cancel()
      }
    })
    commit(authMutationTypes.CLEAR_CANCEL_TOKENS)
  },
  async encryptIdProUser({ state, commit }) {
    try {
      if (!state.encryptIdProUser) {
        const url = 'user/navision/encrypted/get'
        const { data } = await new MainService().getData(url)
        commit(authMutationTypes.ENCRYPT_ID_PRO_USER, data)
      }
    } catch (error) {}
  },
  async saveUserNif({ commit, state }, nif: string) {
    try {
      if (!state.authData || !state.authData.user || !state.authData.user.id) {
        throw new Error('Missing user on AuthData')
      }
      const url = 'user'
      const postData = { dni: nif, id: state.authData.user.id }
      await new MainService().postData(url, postData)
      commit(authMutationTypes.SET_USER_NIF, nif)
      return { result: 'Ok', code: 0 }
    } catch (error) {
      return { result: 'Fail', code: -1 }
    }
  },
  async dfaLogin({commit}, uuid: string) {
    try {
      const url = `dfa/login?uuid=${uuid}`
      const { data } = await new MainService().getData(url)
      commit(authMutationTypes.SET_PRE_AUTH_DATA, data)
      return data.navisionToken
    } catch (error) {}
  },
  async loadTools({commit}) {
    try {
      const url = `v2/user/tool`
      const { data } = await new MainService().getData(url)
      commit(authMutationTypes.SET_USER_TOOLS, data)
    } catch (error) {
      commit(authMutationTypes.SET_USER_TOOLS, [])
    }
  }
}
