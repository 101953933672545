import {
  ActionEntityId,
  agendaEntityId,
  configurationEntityId,
  legalBaseEntityId,
  reportsEntityId
} from '@/general/entityIds'

export enum UsersMaintenanceComponentMode {
  GRID = 'grid',
  TABS = 'tabs',
  SPINNER = 'spinner'
}

export enum UsersMaintenanceCustomPermissionsComponentCheckboxType {
  EDIT = 'edit',
  REMOVE = 'remove',
  SEE = 'see'
}

export enum UsersMaintenanceTabs {
  GENERAL_DATA = 0,
  PROFESSIONAL_ACCESS = 1,
  CUSTOM_PERMISSIONS = 2
}

export const entitiesWithDisableCheckbox: number[] = [
  agendaEntityId,
  ActionEntityId.EMAIL,
  reportsEntityId,
  ActionEntityId.LEXNET,
  configurationEntityId,
  legalBaseEntityId
]

export interface UsersMaintenanceCustomPermissionsComponentData {
  allSelected: boolean
  idUser: number
  permissions: UsersMaintenanceCustomPermissionsComponentPermission[]
}

export interface UsersMaintenanceCustomPermissionsComponentPermission {
  idEntity: number
  label: string
  subEntities?: UsersMaintenanceCustomPermissionsComponentSubEntityData
  activated: boolean
  type: UsersMaintenanceCustomPermissionsComponentPermissionType
}

interface UsersMaintenanceCustomPermissionsComponentPermissionType {
  edit: boolean
  remove: boolean
  see: boolean
}

interface UsersMaintenanceCustomPermissionsComponentSubEntityData {
  panelIsOpened: boolean
  autocompleteLabel: string
  selectedItems: number[]
  items: UsersMaintenanceCustomPermissionsComponentSubEntity[]
}

export interface UsersMaintenanceCustomPermissionsComponentSubEntity {
  idEntity: number
  name: string
}
