import { render, staticRenderFns } from "./FormGeneratorComponent.vue?vue&type=template&id=0cf87db4&scoped=true&lang=pug"
import script from "./FormGeneratorComponent.vue?vue&type=script&lang=ts"
export * from "./FormGeneratorComponent.vue?vue&type=script&lang=ts"
import style0 from "./FormGeneratorComponent.vue?vue&type=style&index=0&id=0cf87db4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0cf87db4",
  null
  
)

export default component.exports