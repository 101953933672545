import Vue from 'vue'
import Vuex, { StoreOptions } from 'vuex'
import VuexPersistence from 'vuex-persist'
import { ModuleNamespaces, RootState } from '@/store/types/storeGlobalTypes'
import { actionsModule } from '@/store/modules/actions/actionsIndex'
import { subActionsModule } from '@/store/modules/sub-actions/subActionsIndex'
import { alertsModule } from '@/store/modules/alerts/alertsIndex'
import { alertsMessagesModule } from '@/store/modules/alertsMessages/alertsMessagesIndex'
import { alertsConfigurationModule } from '@/store/modules/alertsConfiguration/alertsConfigurationIndex'
import { appointmentsModule } from '@/store/modules/appointments/appointmentsIndex'
import { auditsModule } from '@/store/modules/audits/auditsIndex'
import { authModule } from '@/store/modules/auth/authIndex'
import { billingModule } from '@/store/modules/billing/billingIndex'
import { configurationModule } from '@/store/modules/configuration/configurationIndex'
import { configurationPanelModule } from '@/store/modules/configurationPanel/configurationPanelIndex'
import { contactsModule } from '@/store/modules/contacts/contactsIndex'
import { contextMenuModule } from '@/store/modules/contextMenu/contextMenuIndex'
import { customFieldsModule } from '@/store/modules/customFields/customFieldsIndex'
import { customerPortalModule } from '@/store/modules/customerPortal/customerPortalIndex'
import { dashboardWidgetsModule } from '@/store/modules/dashboardWidgets/dashboardWidgetsIndex'
import { dialogModule } from '@/store/modules/dialog/dialogIndex'
import { documentOnlineModule } from '@/store/modules/documentOnline/documentOnlineIndex'
import { endpointModule } from '@/store/modules/endpoint/endpointIndex'
import { entitiesModule } from '@/store/modules/entities/entitiesIndex'
import { errorsModule } from '@/store/modules/errors/errorsIndex'
import { expedientsModule } from '@/store/modules/expedients/expedientsIndex'
import { fileManagerModule } from '@/store/modules/fileManager/fileManagerIndex'
import { firmaModule } from '@/store/modules/firma/firmaIndex'
import { formsModule } from '@/store/modules/forms/formsIndex'
import { globalModule } from '@/store/modules/global/globalIndex'
import { invoicesModule } from '@/store/modules/invoices/invoicesIndex'
import { legalSearchModule } from '@/store/modules/legalSearch/legalSearchIndex'
import { lexboxModule } from '@/store/modules/lexbox/lexboxIndex'
import { listItemsModule } from '@/store/modules/listItems/listItemsIndex'
import { maintenanceModule } from '@/store/modules/maintenance/maintenanceIndex'
import { menusModule } from '@/store/modules/menus/menusIndex'
import { notesModule } from '@/store/modules/notes/notesIndex'
import { notificationsModule } from '@/store/modules/notifications/notificationsIndex'
import { numerationsModule } from '@/store/modules/numerations/numerationsIndex'
import { professionalsModule } from '@/store/modules/professionals/professionalsIndex'
import { reportsModule } from '@/store/modules/reports/reportsIndex'
import { routerModule } from '@/store/modules/router/routerIndex'
import { searchModule } from '@/store/modules/search/searchIndex'
import { securityModule } from '@/store/modules/security/securityIndex'
import { selectedRegisterModule } from '@/store/modules/selectedRegister/selectedRegisterIndex'
import { spinnerModule } from '@/store/modules/spinner/spinnerIndex'
import { tabsModule } from '@/store/modules/tabs/tabsIndex'
import { tandemSearchModule } from '@/store/modules/tandemSearch/tandemSearchIndex'
import { templateModule } from '@/store/modules/template/templateIndex'
import { usersModule } from '@/store/modules/users/usersIndex'

Vue.use(Vuex)

const vuexLocalStorage = new VuexPersistence<RootState>({
  key: 'lex-on-storage',
  storage: window.localStorage,
  reducer: (state) => ({
    selectedRegisterModule: {
      selectedRegisterData: state.selectedRegisterModule.selectedRegisterData,
      selectedRegisterId: state.selectedRegisterModule.selectedRegisterId
    },
    entitiesModule: {
      selectedEntityName: state.entitiesModule.selectedEntityName,
      menuParentCategoryName: state.entitiesModule.menuParentCategoryName,
      selectedIdEntityType: state.entitiesModule.idEntityType
    },
    configurationModule: {
      userProfileConfiguration: state.configurationModule.userProfileConfiguration
    },
    appointmentsModule: state.appointmentsModule,
    menusModule: state.menusModule,
    tasksModule: state.tasksModule,
    expedientsModule: {
      sectionsToDuplicate: state.expedientsModule.sectionsToDuplicate
    },
    routerModule: state.routerModule,
    reportsModule: {
      selectedReportList: state.reportsModule.selectedReportList
    }
  })
})

const store: StoreOptions<RootState> = {
  plugins: [vuexLocalStorage.plugin],
  state: {
    version: '2.0.0'
  },
  modules: {
    actionsModule,
    subActionsModule,
    alertsConfigurationModule,
    alertsMessagesModule,
    alertsModule,
    appointmentsModule,
    auditsModule,
    authModule,
    billingModule,
    configurationModule,
    configurationPanelModule,
    contactsModule,
    contextMenuModule,
    customFieldsModule,
    customerPortalModule,
    dashboardWidgetsModule,
    dialogModule,
    documentOnlineModule,
    endpointModule,
    entitiesModule,
    errorsModule,
    expedientsModule,
    fileManagerModule,
    firmaModule,
    formsModule,
    globalModule,
    invoicesModule,
    legalSearchModule,
    lexboxModule,
    listItemsModule,
    maintenanceModule,
    menusModule,
    notesModule,
    notificationsModule,
    numerationsModule,
    professionalsModule,
    reportsModule,
    routerModule,
    searchModule,
    securityModule,
    selectedRegisterModule,
    spinnerModule,
    tabsModule,
    tandemSearchModule,
    templateModule,
    usersModule
  }
}

export default new Vuex.Store<RootState>(store)

export const initialStateCopy = JSON.parse(JSON.stringify(store.state))

export const resetState = (store: any) => {
  const persistentModules = [ModuleNamespaces.NOTIFICATIONS, ModuleNamespaces.ALERTS, ModuleNamespaces.ROUTER]

  Object.keys(store.state).forEach((key) => {
    if (!persistentModules.includes(key as ModuleNamespaces)) {
      store.state[key] = initialStateCopy[key]
    }
  })
}

export const resetInitStateProperties = () => {
  initialStateCopy.authModule.authData = null
  initialStateCopy.authModule.companyName = ''
  initialStateCopy.menusModule.siteMenu = []
  initialStateCopy.menusModule.configurationMenu = []
}
