import store from '@/store/store'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'

export default function useParentFields() {

  const setParentFieldValues = (data: any) => {
    store.dispatch(`${ ModuleNamespaces.FORMS }/setParentFieldValues`, data)
  }

  const getParentFieldValues = () => {
    const fieldValues: object | null = store.getters[`${ModuleNamespaces.FORMS}/getParentFieldValues`]()
    return fieldValues
  }

  const setParentFormContext = (context: string | null) => {
    store.dispatch(`${ ModuleNamespaces.FORMS }/setParentFormContext`, context)
  }

  const getParentFormContext = () => {
    const context: string | null = store.getters[`${ModuleNamespaces.FORMS}/getParentFormContext`]()
    return context
  }
  
  const getSelectedParentFieldValue = (fieldName: string) => {
    return store.getters[`${ModuleNamespaces.FORMS}/getSelectParentFieldValue`](fieldName)
  }

  const setSelectedParentFieldValue = (fieldName: string, value: any) => {
    store.dispatch(`${ ModuleNamespaces.FORMS }/setSelectParentFieldValue`, {fieldName, value})
  }

  return {
    setParentFieldValues,
    getParentFieldValues,
    getSelectedParentFieldValue,
    setSelectedParentFieldValue,
    setParentFormContext,
    getParentFormContext
  }
}
