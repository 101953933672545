import store from '@/store/store'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'

interface ReturnUseSpinner {
  showSpinner: (showMessage: boolean) => Promise<void>
  hideSpinner: () => Promise<void>
  showSpinnerLayer: () => Promise<void>
  hideSpinnerLayer: () => Promise<void>
}

export default function useSpinner(): ReturnUseSpinner {
  const showSpinner = async ( showMessage: boolean) => {
    store.dispatch(`${ModuleNamespaces.SPINNER}/showSpinner`,  showMessage )
  }

  const hideSpinner = async () => {
    store.dispatch(`${ModuleNamespaces.SPINNER}/hideSpinner`)
  }

  const showSpinnerLayer = async () => {
    store.dispatch(`${ModuleNamespaces.SPINNER}/showSpinnerLayer`)
  }

  const hideSpinnerLayer = async () => {
    store.dispatch(`${ModuleNamespaces.SPINNER}/hideSpinnerLayer`)
  }

  return {
    showSpinner,
    hideSpinner,
    showSpinnerLayer,
    hideSpinnerLayer
  }
}
