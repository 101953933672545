export enum TaxesFormFields {
  AREA = 'idArea',
  CHARGED = 'charged',
  CONCEPT = 'idConcepto',
  DISCOUNT_FIELDSET = 'appliedDiscount',
  DISCOUNT_TYPE = 'idDiscountType',
  DISCOUNT_VALUE = 'discountValue',
  IS_PROVISION = 'isProvision',
  MODULE = 'idModulo',
  PERIODIC_BILLING_ID_ECONOMIC_DATA_TYPE = 'periodicBillingidEconomicDataType',
  TYPE = 'idEconomicDataType'
}
