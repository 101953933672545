import { b64toBlob, createLink } from '@/helpers/blob'
import { AlertsTypes, ComponentWhereIsRendered } from '@/store/modules/alerts/alertsTypes'
import store from '@/store/store'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { i18n } from '@/plugins/vue-i18n'

interface Filter {
  templateName: string
  idEntityOrigin: number | string
  name: string
  folderTargetPath: string
  idEntity: number | string
  idEntityType: number | string
  convertToPDF: boolean
  saveDocument: boolean
}

export const printInvoice = async (
  templateSelected: any,
  idEntity: number | string,
  idEntityType: number | string,
  whereRenderAlert: ComponentWhereIsRendered | null = ComponentWhereIsRendered.TABS_VIEW
) => {
  const templateModule: string = ModuleNamespaces.TEMPLATE
  const alertsModule: string = ModuleNamespaces.ALERTS

  const filter: Filter = {
    templateName: templateSelected.templateName,
    idEntityOrigin: templateSelected.idEntity,
    name: templateSelected.name,
    folderTargetPath: '',
    idEntity,
    idEntityType,
    convertToPDF: true,
    saveDocument: false
  }

  const data: any = await store.dispatch(`${templateModule}/generateTemplate`, filter)

  if (data) {
    const blob: Blob = b64toBlob(data.file, 'application/pdf')
    const blobUrl = URL.createObjectURL(blob)
    createLink(blobUrl, '', true)
    await store.dispatch(`${alertsModule}/showAlert`, {
      type: AlertsTypes.SUCCESS,
      message: i18n.t('components.file_manager.advises.template_created'),
      componentWhereIsRendered: whereRenderAlert
    })
  } else {
    await store.dispatch(`${alertsModule}/showAlert`, {
      type: AlertsTypes.ERROR,
      message: i18n.t('components.file_manager.advises.template_error'),
      componentWhereIsRendered: whereRenderAlert
    })
  }
}

export const generateInvoice = async (
  templateSelected: any,
  idEntity: number | string,
  idEntityType: number | string,
  saveDocument: boolean,
  printDocument: boolean
) => {
  const invoiceModule: string = ModuleNamespaces.INVOICES

  const filter: Filter = {
    templateName: templateSelected.templateName,
    idEntityOrigin: templateSelected.idEntity,
    name: templateSelected.name,
    folderTargetPath: '',
    idEntity,
    idEntityType,
    convertToPDF: printDocument,
    saveDocument
  }

  await store.dispatch(`${invoiceModule}/generateInvoice`, filter)
}
