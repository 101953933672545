<template>
  <div class="header-wrapper">
    <header ref="textContainerElement">
      <LfHeaderTooltipComponent v-if="isTruncated" :title="props.title" />
      <span v-else ref="textElement">{{ props.title }}</span>
    </header>
    <slot></slot>
    <LfActionsBarComponent
      v-if="showButtons"
      class="actions-bar"
      :buttons="props.buttons"
      :showKebabMenu="props.showKebabMenu"
      @execute-action="executeAction"
    />
    <AlertComponent :whereIsRendered="componentWhereIsRendered" />
  </div>
</template>

<script lang="ts" setup>
import { PropType, computed, ref, watch } from 'vue'
import LfActionsBarComponent from '@/components/ActionsBar/LfActionsBarComponent.vue'
import { ActionBarButton } from '@/components/ActionsBar/types/ActionBarComponentTypes'
import { ComponentWhereIsRendered } from '@/store/modules/alerts/alertsTypes'
import AlertComponent from '@/components/Alert/AlertComponent.vue'
import LfHeaderTooltipComponent from '@/components/HeaderTitle/LfHeaderTooltipComponent.vue'

type ActionFunction = ((payload: MouseEvent) => void) | ((actionName: string) => Promise<void>)

// PROPS
const props = defineProps({
  title: {
    type: String as PropType<string>,
    required: true
  },
  buttons: {
    type: Array as PropType<ActionBarButton[]>,
    default: () => []
  },
  executeAction: {
    type: Function as PropType<ActionFunction>,
    required: false
  },
  showKebabMenu: {
    type: Boolean as PropType<boolean>,
    default: false
  }
})

// DATA
const componentWhereIsRendered = ComponentWhereIsRendered.LF_HEADER_TITLE
// Referencias para comprobar si se renderiza una elipsis en el nombre
const textContainerElement = ref<Element | null>(null)
const textElement = ref<Element | null>(null)
const isTruncated = ref(false)

// COMPUTED
const showButtons = computed(() => props.buttons && props.buttons.length > 0)

// WATCHERS
watch(
  () => props.title,
  () => {
    setTimeout(() => {
      checkIfTruncated()
    })
  }
)

// METHODS
function checkIfTruncated() {
  if (textContainerElement.value && textElement.value) {
    const container = textContainerElement.value
    const text = textElement.value
    isTruncated.value = text.scrollWidth > container.clientWidth
  }
}
</script>

<style lang="scss" scoped>
.header-wrapper {
  @include flex($align-items: flex-end);
  @include border($width: 2px, $color: $neutral-grey-300);
  --activator-line-height: auto;
  --activator-overflow: hidden;
  position: relative;
  width: 100%;
  height: 54px;
  padding-bottom: $spacing-xs;

  header {
    @include flex($align-items: flex-end, $justify-content: flex-start);
    @include milano-bold-24;
    width: 100%;
    color: $highlight-200;
    overflow: hidden;

    span {
      @include ellipsis;
      display: block;
    }

    span::first-letter {
      text-transform: capitalize;
    }
  }

  ::v-deep .alert-container {
    position: absolute;
  }
}

.header-title {
  margin-bottom: 21px;
}
</style>
